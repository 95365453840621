import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    mainvisual() {
        //メインスライダー
        new Swiper('.mainvisual-slider[data-plugin="mainSlider"]', {
            slidesPerView: 'auto',
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.mainvisual .swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.mainvisual .swiper-button-next',
                prevEl: '.mainvisual .swiper-button-prev',
            },
            breakpoints: {
                576: {
                    centeredSlides: true,
                    spaceBetween: 35,
                },
            },
        });
    }
}

const domReady = (TPL) => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.mainvisual();
    });
};

const jqInit = () => false;

export default function HOME() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));

    $(() => jqInit());
}
